/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import Footer from "./Footer"
import Header from "./Header"

const Layout: React.FunctionComponent = ({ children }) => {
  const { site } = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)
    
    return (
      <div style={{position: "relative"}}>
        <Header siteTitle={site.siteMetadata.title} />
        <main>{children}</main>
        <Footer />
      </div>
    )
}

export default Layout
