import React from "react"

const DefaultBackground = () => <div />

interface ContainerProps {
  Background?: React.FunctionComponent
}

const Section: React.FunctionComponent<ContainerProps> = ({ children, Background = DefaultBackground }) => (
  <div style={{ position: "relative" }}>
    <Background />
    <div style={{
      display: "flex",
      flex: "0 1 auto",
      flexDirection: "column",
      justifyContent: "center",
      margin: "auto",
      maxWidth: "1366px",
      minHeight: "100vh",
      minWidth: "320px",
      padding: "5em 1em",
      scrollBehavior: "smooth",
    }}>
      {children}
    </div>
  </div>
)

export default Section