import React from "react"

interface TriangleProps {
  color: string,
  height: number,
  invertX?: boolean,
  invertY?: boolean,
  width: number
}

const Triangle = (props: TriangleProps) => {
  const { color, height, width, invertX, invertY } = props
  const rotation = []
  if (invertX) {
    rotation.push("rotateY(180deg)")
  }
  if (invertY) {
    rotation.push("rotateX(180deg)")
  }
  return (
    <svg 
      viewBox="0, 0, 100, 100" 
      preserveAspectRatio="none" 
      style={{
        position: "absolute",
        transform: rotation.join(" "),
        zIndex: -2
      }} 
      width="100%" 
      height="100%">
      <polygon points={`0,0 ${width},0 0,${height}`} style={{fill: color}} />
    </svg>
  )
}

export default Triangle