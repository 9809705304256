import React from "react" 

const Footer = () => {
  return (
    <footer style={{position: "absolute", width: "100%", bottom: 0}}>
      <span>© {new Date().getFullYear()}, Built with </span>
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </footer>
  )
}

export default Footer