import { Link } from "gatsby"
import React from "react"
import Headroom from "react-headroom"
import styled from "styled-components"

import { primaryDark } from "../styles/colors.module.scss"

interface Props { 
  siteTitle: string 
}

const StyledHeadroom = styled(Headroom)`
  .headroom--pinned {
    background-color: ${primaryDark}
  }
`

const Header = ({ siteTitle }: Props) => (
  <StyledHeadroom wrapperStyle={{position: "absolute", width: "100%"}}>
    <header style={{
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}>
      <h1 style={{ margin: 0 }}>
        <Link to="/" style={{
          color: `white`,
          textDecoration: `none`,
        }}>
          {siteTitle}
        </Link>
      </h1>
    </header>
  </StyledHeadroom>
)

export default Header
